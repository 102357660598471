<template>
  <v-row class="row--35 align-center">
    <v-col md="5" sm="12" cols="12">
      <div class="thumbnail">
        <img
          class="w-100"
          src="../../assets/images/about/about-8.jpg"
          alt="About Images"
        />
      </div>
    </v-col>
    <v-col md="7" sm="12" cols="12">
      <div class="about-inner inner">
        <div class="section-title">
          <h2 class="heading-title">About Me</h2>
          <p class="description">
            Since I began my self-taught journey more than 15 years ago,
            I have collaborated with multiple companies and consultancies,
            specializing in backend developments for Telco (America Movil/Telefonica del Peru).
            Currently, I collaborate with free software communities and
            am developing platforms for my own business purposes.
          </p>
        </div>
        <v-row>
          <v-col md="12" sm="12" cols="12">
            <div class="about-us-list">
              <h5 class="heading-title">My Skills</h5>
              <p>
                <button class="btn-default btn-border btn-opacity size-sm ml--5 mr--5 mb--5">Python</button>
                <button class="btn-default btn-border btn-opacity size-sm ml--5 mr--5 mb--5">WebScraping</button>
                <button class="btn-default btn-border btn-opacity size-sm ml--5 mr--5 mb--5">Data Manipulation</button>
                <button class="btn-default btn-border btn-opacity size-sm ml--5 mr--5 mb--5">RPA</button>
                <button class="btn-default btn-border btn-opacity size-sm ml--5 mr--5 mb--5">APIs</button>
                <button class="btn-default btn-border btn-opacity size-sm ml--5 mr--5 mb--5">Github</button>
                <button class="btn-default btn-border btn-opacity size-sm ml--5 mr--5 mb--5">Docker</button>
                <button class="btn-default btn-border btn-opacity size-sm ml--5 mr--5 mb--5">Kubernetes</button>
                <button class="btn-default btn-border btn-opacity size-sm ml--5 mr--5 mb--5">Nginx</button>
                <button class="btn-default btn-border btn-opacity size-sm ml--5 mr--5 mb--5">VueJS</button>
                <button class="btn-default btn-border btn-opacity size-sm ml--5 mr--5 mb--5">Flask</button>
                <button class="btn-default btn-border btn-opacity size-sm ml--5 mr--5 mb--5">FastAPI</button>
                <button class="btn-default btn-border btn-opacity size-sm ml--5 mr--5 mb--5">Jenkins</button>
                <button class="btn-default btn-border btn-opacity size-sm ml--5 mr--5 mb--5">Splunk</button>
                <button class="btn-default btn-border btn-opacity size-sm ml--5 mr--5 mb--5">MySQL</button>
                <button class="btn-default btn-border btn-opacity size-sm ml--5 mr--5 mb--5">Oracle</button>
                <button class="btn-default btn-border btn-opacity size-sm ml--5 mr--5 mb--5">SqlServer</button>
                <button class="btn-default btn-border btn-opacity size-sm ml--5 mr--5 mb--5">MongoDB</button>
                <button class="btn-default btn-border btn-opacity size-sm ml--5 mr--5 mb--5">Bash Linux</button>
                <button class="btn-default btn-border btn-opacity size-sm ml--5 mr--5 mb--5">Debian</button>
                <button class="btn-default btn-border btn-opacity size-sm ml--5 mr--5 mb--5">OCS</button>
                <button class="btn-default btn-border btn-opacity size-sm ml--5 mr--5 mb--5">DataCharging</button>
                <button class="btn-default btn-border btn-opacity size-sm ml--5 mr--5 mb--5">Virtualization</button>
                <button class="btn-default btn-border btn-opacity size-sm ml--5 mr--5 mb--5">Carbonio</button>
                <button class="btn-default btn-border btn-opacity size-sm ml--5 mr--5 mb--5">Java</button>
                <button class="btn-default btn-border btn-opacity size-sm ml--5 mr--5 mb--5">WebLogic</button>
                <button class="btn-default btn-border btn-opacity size-sm ml--5 mr--5 mb--5">PHP</button>
              </p>
            </div>
          </v-col>
          
        </v-row>
      </div>
    </v-col>
  </v-row>
</template>

<script>
  export default {};
</script>

<style lang="scss" scoped></style>
