<template>
  <v-row>
    <!-- Start Single Portfolio  -->
    <v-col
      lg="4"
      md="4"
      sm="6"
      cols="12"
      class="mt--40"
      v-for="(item, i) in portfolioContent"
      :key="i"
    >
      <div class="im_portfolio text-center">
        <div class="thumbnail_inner">
          <div class="thumbnail">
            <a href="portfolio-details.html">
              <img class="w-100" :src="item.src" alt="Portfolio Images" />
            </a>
          </div>
        </div>
        <div class="content">
          <div class="inner">
            <div class="portfolio_heading">
              <div class="category_list">
                <a :href="item.url">{{ item.category }}</a>
              </div>
              <h4 class="heading-title">
                <a :href="item.url">{{ item.title }}</a>
              </h4>
            </div>
            <div class="portfolio_hover">
              <p>{{ item.description }}</p>
            </div>
          </div>
        </div>
        <a class="transparent_link" :href="item.url"></a>
      </div>
    </v-col>
    <!-- End Single Portfolio  -->
  </v-row>
</template>

<script>
  export default {
    data() {
      return {
        portfolioContent: [
          {
            src: require("../../assets/images/portfolio/portafolio-carbonio.jpg"),
            category: "Bash Utilities",
            title: "Carbonio Install Bash & Utilities",
            description:
              "Tools and scripts around working with zimbra and carbonio.",
            url: "https://github.com/jbolo/carbonio-tools/"
          },
          {
            src: require("../../assets/images/portfolio/webscraping.jpg"),
            category: "WebScraping Python",
            title: "Scraping of Sunat and data manipulation",
            description:
              "WebScraping of government website and saves it in a mysql database.",
            url: "https://github.com/jbolo/py-simple-webscraping-sunat/"
          },
          {
            src: require("../../assets/images/portfolio/comming_soon.jpg"),
            category: "Development",
            title: "Development",
            description:
              "Lorem ipsum dolor sit amet, consec tetur adipiscing elit.",
              url: ""
          },
        ],
      };
    },
  };
</script>
